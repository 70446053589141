<script>
import { h } from '@vue/runtime-core'
export default {
  props: {
    from: Number,
    to: Number,
    itemClass: {
      default: false
    }
  },
  setup (props, context) {
    const slots = context.slots.default()
    function r () {
      let items = (slots[0].children[0].children || []).slice(props.from, props.to)
      const count = items.length
      items = items.map((item, i) => {
        const classes = []
        if (props.itemClass) {
          if (props.itemClass === 'large-image-column' || props.itemClass === '2-column' || props.itemClass === 'option-3' || props.itemClass === 'option-2') {
            classes.push('col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12')
          } else if (props.itemClass === '3-column') {
            classes.push('col')
          } else if (props.itemClass === '6-column' || props.itemClass === 'list') {
            classes.push('col-xl-2 col-lg-4 col-md-4 col-sm-6 col-12')
          } else {
            classes.push(props.itemClass)
          }
        } else {
          // classes.push('col');
          if (count === 6) {
            classes.push('col-xl-2 col-lg-4 col-md-4 col-sm-6 col-12')
          } else if (count === 2) {
            classes.push('col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12')
          } else if (count === 3) {
            classes.push('col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12')
          } else if (count === 5) {
            classes.push('col-xl-2 col-lg-4 col-md-4 col-sm-6 col-12')
          } else if (count === 4) {
            classes.push('col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12')
          } else {
            classes.push('col-xl-2 col-lg-4 col-md-4 col-sm-6 col-12')
          }
        }
        return h('div', { key: i, class: classes.join(' ') }, [item])
      })
      return items
    }
    return () => {
      return h('div', { class: 'row options' }, r())
    }
  }
}
</script>
